/* eslint-disable */
import EmaerketComponent from '../component/Emaerket';

const EMAERKET = 'Emaerket';
const addEmaerket = (member, instance) => {
    return {
        ...member,
        [EMAERKET]: {
            component: EmaerketComponent
        }
    }
}

export default {
    'Component/WidgetFactory/Component': {
        'member-property': {
            'renderMap': addEmaerket
        }
    },
}