/**
 * Trustpilot scripts
 */
 class Scripts {
    static getScript(id) {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = `https://widget.emaerket.dk/js/${id}`;
        if (typeof(script.async) != "undefined") { script.async = true; }
        return script;
    }
}

export default Scripts;
