import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Scripts from './Scripts';

export const EMAERKET_INJECTION_TIMEOUT = 500;

export class EmaerketScript extends PureComponent {
    static propTypes = {
        enabled: PropTypes.bool.isRequired,
        identifier: PropTypes.string
    }

    initialize() {
        const { enabled } = this.props;
        if (this.enabled || !enabled) {
            return;
        }
        this.enabled = true;
        this.injectScripts();
    }

    componentDidMount() {
        this.initialize();
    }

    componentDidUpdate() {
        this.initialize();
    }

    injectScripts() {
        const { identifier } = this.props;
        const script = Scripts.getScript(identifier); 
        setTimeout(() => {
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(script, s);
        }, EMAERKET_INJECTION_TIMEOUT);
    }

    render() {
        return null;
    }
}

export default EmaerketScript;
