/* eslint-disable */
import { BEFORE_ITEMS_TYPE } from 'Component/Router/Router.config';
import EmaerketScript from '../component/EmaerketScript';

const addEmaerket = (member, instance) => {
    return [
        ...member,
        {
            component: <EmaerketScript />,
            position: 24
        }
    ]
}

export default {
    'Component/Router/Component': {
        'member-property': {
            [BEFORE_ITEMS_TYPE]: addEmaerket
        }
    }
}