import { connect } from 'react-redux';

import EmaerketScript from './EmaerketScript.component';

const mapStateToProps = state => ({
    enabled: state.ConfigReducer.emaerket_enabled,
    identifier: state.ConfigReducer.emaerket_identifier
});

const mapDispatchToProps = dispatch => ({
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(EmaerketScript);
