/* eslint-disable */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { MixType } from 'Type/Common';

/** @namespace Hoeks/Emaerket/Component/Emaerket/Component/EmaerketComponent */
export class EmaerketComponent extends PureComponent {
    static propTypes = {
        id: PropTypes.string.isRequired,
        mix: MixType
    };

    static defaultProps = {
        mix: {}
    }

    componentDidMount() {
        this.reload();
    }

    reload() {
        if (typeof(window.EmaerketWidget) !== 'undefined'){
            EmaerketWidget.init(EmaerketWidget);
            EmaerketWidget.detect(window);
        }
    }

    render() {
        const { id, mix} = this.props;
        return (
            <div id={ `emaerket-widget-${id}` } mix={mix} />
        );
    }
}
export default EmaerketComponent;
