const _getStoreConfigFields = (args, callback, instance) => {
    return [
        ...callback(args),
        'emaerket_enabled',
        'emaerket_identifier'
    ];
}

export default {
    'Query/Config': {
        'member-function': {
            _getStoreConfigFields
        }
    }
}